<!-- START TEAM-->
<section class="section bg-light" id="team">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-12">
                <i class="ti-user title-icon text-muted"></i>
                <h3 class="title">Our <span class="fw-bold">Team</span></h3>
                <p class="text-muted mt-3 title-subtitle mx-auto">It is a long established fact that a reader will
                    be of a page when established fact looking at its layout.</p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-4">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/team-3.png" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Itamar Ribeiro</p>
                        <p class="text-muted mt-4">Founder, Product</p>
                    </div>
                    <div class="">
                        <ul class="list-inline team-social mt-4 mb-0">
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-github"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-skype"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-twitter-alt"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="text-center bg-white team-box mt-3 active p-5">
                    <div class="">
                        <img src="assets/images/team/team-2.png" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Juliano Sales</p>
                        <p class="text-muted mt-4">Founder, Architect</p>
                    </div>
                    <div class="">
                        <ul class="list-inline team-social mt-4 mb-0">
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-github"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-skype"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-twitter-alt"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/team-1.png" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Renata Guedes</p>
                        <p class="text-muted mt-4"> Co-Founder, Development</p>
                    </div>
                    <div class="">
                        <ul class="list-inline team-social mt-4 mb-0">
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-github"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-skype"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-twitter-alt"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
           <!--  <div class="col-lg-4">
                <div class="text-center bg-white team-box mt-3 active p-5">
                    <div class="">
                        <img src="assets/images/team/team-2.png" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Juliano Sales</p>
                        <p class="text-muted mt-4">Co-Founder, <br>  Blockchain Architect</p>
                    </div>
                    <div class="">
                        <ul class="list-inline team-social mt-4 mb-0">
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-github"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-skype"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-twitter-alt"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="text-center bg-white team-box mt-3 active p-5">
                    <div class="">
                        <img src="assets/images/team/team-2.png" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Juliano Sales</p>
                        <p class="text-muted mt-4">Co-Founder, <br>  Blockchain Architect</p>
                    </div>
                    <div class="">
                        <ul class="list-inline team-social mt-4 mb-0">
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-github"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-skype"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-twitter-alt"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/team-1.png" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Itamar Ribeiro</p>
                        <p class="text-muted mt-4"> Co-Founder, <br> Product Manager</p>
                    </div>
                    <div class="">
                        <ul class="list-inline team-social mt-4 mb-0">
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-github"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-skype"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0);"><i class="ti-twitter-alt"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div> -->



        </div>
    </div>
</section>
<!-- END TEAM -->