import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-forget',
  templateUrl: './password-forget.component.html',
  styleUrls: ['./password-forget.component.scss']
})
export class PasswordForgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.getElementById('color-opt').setAttribute('href', 'assets/css/colors/green.css');
  }

}
