<!-- START FETURES -->
<section class="section bg-light" id="features">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-12">
                <i class=" title-icon text-muted"></i>
                <h3 class="title">Our <span class="fw-bold">Cases</span></h3>
                <p class="text-muted mt-3 title-subtitle mx-auto">Effortless<strong> Intellectual Property </strong> Protection. Secure Your Innovation, Worldwide.</p>
            </div>
        </div>
        <div class="row mt-5 vertical-content">
            <div class="col-lg-6 mt-2">
                <div>
                    <img src="assets/images/mockup2.png" alt="" class="img-fluid mx-auto d-block">
                </div>
            </div>
            <div class="col-lg-5 offset-lg-1 mt-2">
                <div class="features-desc">
                    <h2>Intellectual Property</h2>
                    <p class="text-muted mt-3">Effortlessly register your creations, ensuring immutability, transparency, and global recognition.<br>
                        Empower yourself with secure and user-friendly IP protection. <br>
                        Protect your innovations with the future of IP management. <br>
                                             
                    </p>
                    <a href="javascript:void(0);" class="btn btn-primary btn-round mt-3">Read more<i
                            class="mdi mdi-chevron-right"></i></a>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-5 pt-5 vertical-content">
            <div class="col-lg-5 mt-2">
                <div class="features-desc">
                    <h2>Key Features: </h2>
                    <p class="text-muted mt-3">
                        <strong>Effortlessly register</strong> your creations with a streamlined process. <br>
                        <strong>Guarantee the unalterability</strong> of your intellectual property with blockchain technology. <br>
                        <strong>Maintain clear and verifiable ownership</strong> records for complete transparency. <br>
                        <strong>Gain worldwide recognition</strong> and enforce your intellectual property rights globally. <br>
                        <strong>Experience user-friendly security</strong> that protects your innovations with ease.
                      </p>
                      
                    <a href="javascript:void(0);" class="btn btn-primary btn-round mt-3">Read more<i class="mdi mdi-chevron-right"></i></a>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-1 mt-2">
                <div>
                    <img src="assets/images/mockup1.png" alt="" class="img-fluid mx-auto d-block">
                </div>
            </div>
        </div> -->
    </div>
</section>
<!-- END FEATURES -->