<!-- STRAT TESTIMONIAL -->
<section class="section bg-client" id="testi">
    <div class="container">
        <div class="row justify-content-center mt-5 mb-5">
            <div class="col-lg-12">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="text-center w-75 mx-auto">
                            <div class="testi-icon text-white">
                                <i class="ti-quote-left"></i>
                            </div>
                            <div class="mt-3">
                                <p class="user-review text-center">"If you don't believe it or don't get it, I don't have the time to try to convince you, sorry."</p>
                                <div class="">
                                    <img src="assets/images/testi/testi-1.jpg" alt=""
                                        class="rounded-circle testi-user mx-auto d-block">
                                </div>
                                <p class="testi-user-name text-center text-white mb-0 mt-4">- Satoshi Nakamoto</p>
                                <p class="text-muted">
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="text-center w-75 mx-auto">
                            <div class="testi-icon text-white">
                                <i class="ti-quote-left"></i>
                            </div>
                            <div class="mt-3">
                                <p class="user-review text-center">"The main advantage of blockchain technology is supposed to be that it's more secure, but new technologies are generally hard for people to trust, and this paradox can't really be avoided."</p>
                                <div class="">
                                    <img src="assets/images/testi/testi-2.jpg" alt=""
                                        class="rounded-circle testi-user mx-auto d-block">
                                </div>
                                <p class="testi-user-name text-center text-white mb-0 mt-4">- Vitalik Buterin</p>
                                <p class="text-muted">
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="text-center w-75 mx-auto">
                            <div class="testi-icon text-white">
                                <i class="ti-quote-left"></i>
                            </div>
                            <div class="mt-3">
                                <p class="user-review text-center">"The best opportunity was yesterday, the next best one is today. Keep buiding."</p>
                                <div class="">
                                    <img src="assets/images/testi/testi-3.jpg" alt=""
                                        class="rounded-circle testi-user mx-auto d-block">
                                </div>
                                <p class="testi-user-name text-center text-white mb-0 mt-4">- Changpeng Zhao
                                </p>
                                <p class="text-muted">
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star text-warning"></span>
                                    <span class="ti-star"></span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!-- END TESTIMONIAL -->